:local {
    @media (min-width: 719px) {
        .container {
            max-width: 800px;
        }
        .form {
            display: grid;
            /* Create 6 equally sized columns */
            grid-template-columns: repeat(6, 1fr);
            column-gap: 30px;

            /* Make the first and last direct descendant divs span all of the columns */
            & > div:first-of-type,
            & > div:last-of-type {
                grid-column: 1 / -1;
            }

            /* Make the last direct descendant div have a high order to always display last */
            & > div:last-of-type {
                order: 20;
            }

            /* Make direct descendant divs span 3 columns */
            & > div {
                grid-column: span 3;
                order: 2;
            }

            .names {
                /* Span all of the columns */
                grid-column: 1 / -1;
            }

            .email,
            .street1 {
                /* Span columns 1 up to the beginning of 5 */
                grid-column: 1 / 5;
            }

            .dob,
            .street2 {
                /* Span columns 5 to the end */
                grid-column: 5 / -1;
            }

            .city {
                /* Span columns 1 to the beginning of 3 */
                grid-column: 1 / 3;
            }

            .stateZip {
                /* Span columns 3 to the end */
                grid-column: 3 / -1;
            }

            .tradeInIntent {
                /* Make this the second to last div in multi-column layout */
                order: 19;
            }

            .coapplicantIntent {
                /* Make this the second to last div in multi-column layout */
                order: 19;
                grid-column: 1 / -1;
            }

            .ssn {
                order: 20;
            }
        }
    }
    .reveo_cta_wrapper {
      grid-column: 1 / 3;
      display: flex;
      justify-content: center;
      margin-top: 20px;
      width: 100%;
    }
    @media (min-width: 768px ) and (max-width: 800px ) {
        .container {
            max-width: 668px;
        }
    }
}
