:local {
    .valuePropsContainer h6 {
        font-weight: bold;
        font-size: 14px;
        text-transform: uppercase;
    }
    
    .tripleValueProp h6 {
        font-size: 12px;
        text-transform: none;
    }
}
