:local {
    .inputTitle {
        color: #6c757d;
    }
    .container {
        padding-top: 30px;
    }
    .existingLoan {
        text-align: center;
    }
    .checkboxContainer {
        display: flex;
        align-items: center;
        margin: 16px 0px;
    }
    .radioLabel {
        margin-top: 16px;
        color: #6c757d;
    }
    .checkbox {
        /* Remove system-level styling */
        -webkit-appearance: none;
        appearance: none;

        width: 25px;
        height: 25px;
        border-radius: 0;
        position: relative;
        cursor: pointer;
        margin-top: 0px;
        /* Custom styling for the blue checkbox using psuedo element */
        &::before {
            content: "";
            height: 100%;
            width: 100%;
            position: absolute;
            right: 0;
            top: 0;
            background: var(--primary) url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23ffffff' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E") no-repeat center/90%;
            visibility: hidden;
        }
        
        /* Override background color and border class color when checked */
        &:checked {
            background: var(--primary);
            border-color: var(--primary) !important;
        }
        
        /* Make the ::before styles visible when the checkbox is checked */
        &:checked::before {
            /* Use `visibility` instead of `display` to avoid recalculating layout */
            visibility: visible;
        }
    }
    
    @media (min-width: 719px) {
        .container {
            max-width: 800px;
        }
    }
    @media (min-width: 768px ) and (max-width: 800px ) {
        .container {
            max-width: 668px;
        }
    }
}
