:root {
    #root {
        & > div {
            flex-grow: 1;
            display: flex;
            flex-direction: column;

            & > div {
                flex-grow: 1;
                display: flex;
                flex-direction: column;
            }
        }
    }
}

.wrapper {
    height: 100%;
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    form {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        align-items: center;
    }
}

.container {
    padding-top: 25px;
    font-family: Karla, sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    display: flex;
    flex-direction: column;
    max-width: 450px;
    margin-right: 0;
    margin-left: 0;
    height: 100%;
    width: 100%;
    position: relative;
    flex-grow: 1;

    div[role='listbox'] {
        z-index: 105;
    }
}

.title {
    font-family: Karla, sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
    margin-bottom: 0;
}

.subtitle {
    margin-top: 5px;
    margin-bottom: 25px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    max-width: 315px;
    text-align: center;
}

.search {
    display: flex;
    flex-direction: column;
    max-width: 325px;
    width: 325px;
    margin-left: auto;
    margin-right: auto;

    .searchTitle {
        display: block;
        margin-bottom: 10px;
        font-family: Karla, sans-serif;
        font-weight: 700;
        font-size: 16px;
        line-height: 25px;
        text-align: center;
    }

    .groupInputs {
        display: flex;
        justify-content: space-between;

        & > * {
            flex-grow: 1;
            &:nth-child(1) {
                margin-right: 10px;
            }
        }
    }
}

.milesSelectorWrapper {
    & label {
        display: none !important;
    }
}

.zipCodeWrapper {
    position: relative;
    height: 57px;

    & input {
        height: 52px !important;
    }

    & > button {
        position: absolute;
        right: 10px;
        top: 45%;
        transform: translateY(-50%);
    }
}

.results {
    width: 325px;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 50px;
    margin-bottom: 60px;

    .resultsTitle {
        display: block;
        text-align: center;
        font-family: 'Karla', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 26px;
        color: #2366b1;
    }

    .resultsEmpty {
        display: block;
        text-align: center;
        font-family: 'Karla', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 25px;
        color: #30333a;
    }

    & span {
        display: block;
        font-family: 'Karla', sans-serif;
        font-style: normal;
    }

    .dealerName {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: #01040b;
        margin-bottom: 5px;
    }

    .dealerData {
        font-weight: 400;
        font-size: 16px;
        line-height: 25px;
        color: #565b66;
    }
}

.helpText {
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 100px;
    text-align: center;
    color: var(--raven-sub);
    margin-bottom: 20px;
}

.action {
    display: flex;
    margin-bottom: 15px;
    justify-content: center;
}

.expander {
    flex-grow: 1;
}

.loading {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 80px;
}
